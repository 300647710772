<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.new_m", {
                m: $t("message.candidates"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.nameFull") }}
                  </span>
                  <el-form-item prop="name">
                    <el-input 
                      :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="columns.name.title"
                      v-model="form.name"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.middle_name") }}
                  </span>
                  <el-input 
                    :class="mode ? 'input__day' : 'input__night'"
                    :placeholder="columns.middle_name.title"
                    v-model="form.middle_name"
                    size="medium"
                  ></el-input>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.vacancy") }}
                  </span>
                  <select-vacancy
                    :size="'medium'"
                    :id="form.vacancy_ids"
                    v-model="form.vacancy_ids"
                    :multiple="true"
                    
                  >
                  </select-vacancy>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.phone_number_y") }}
                  </span>
                  <crm-input
                    :placeholder="columns.phone_number.title"
                    v-model="form.phone_number"
                    type="tel"
                    autocomplete="off"
                    size="medium"
                    :maskFormat="'tel'"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></crm-input>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.date_of_birth") }}
                  </span>
                  <crm-date-picker
                    :date="form.date_of_birth"
                    :placeholder="columns.date_of_birth.title"
                    v-model="form.date_of_birth"
                    :size="'medium'"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></crm-date-picker>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.status") }}
                  </span>
                  <el-form-item prop="status_id">
                    <select-status
                      :table_name="'candidates'"
                      v-model="form.status_id"
                      :placeholder="columns.status_id.title"
                      :id="form.status_id"
                      :size="'medium'"
                    ></select-status>
                  </el-form-item>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.nation") }}
                  </span>
                  <select-nation
                    :size="'medium'"
                    :placeholder="columns.nation_id.title"
                    :id="form.nation_id"
                    v-model="form.nation_id"
                  >
                  </select-nation>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.where_know") }}
                  </span>
                  <el-form-item>
                    <select-social
                      :size="'medium'"
                      :placeholder="columns.social_id.title"
                      :id="form.social_id"
                      v-model="form.social_id"
                    >
                    </select-social>
                  </el-form-item>
                </div>
                
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.summary") }}
                  </span>
                  <el-upload
                    :on-preview="handleFilePreview"
                    :on-remove="handleFileRemove"
                    :before-remove="beforeFileRemove"
                    action="/"
                    :limit="1"
                    :on-change="createFileList"
                    class="upload-demo"
                    :file-list="fileList"
                    list-type="file"
                    :auto-upload="false"
                    :class="mode ? 'input__day' : 'input__night'"   
                  >
                    <el-button size="small" type="primary">
                      {{ $t("message.UploadFile") }}
                    </el-button>
                    <div slot="tip" class="el-upload__tip">
                      {{ $t("message.UploadFileText") }}
                    </div>
                  </el-upload>
                </div>
              </el-col>
              <!-- end col -->

              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.last_name") }}
                  </span>
                  <el-form-item prop="last_name" size="mini">
                    <el-input 
                      :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="columns.last_name.title"
                      v-model="form.last_name"
                      size="medium"
                    >
                    </el-input>
                  </el-form-item>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.min_wage_y") }}
                  </span>
                  <el-input 
                    :class="mode ? 'input__day' : 'input__night'"
                    :placeholder="columns.min_wage.title"
                    v-model="form.min_wage"
                    type="number"
                    size="medium"
                  >
                  </el-input>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.max_wage_y") }}
                  </span>
                  <el-input 
                    :class="mode ? 'input__day' : 'input__night'"
                    :placeholder="columns.max_wage.title"
                    v-model="form.max_wage"
                    size="medium"
                    type="number"
                  ></el-input>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.email") }}
                  </span>
                  <el-input 
                    :class="mode ? 'input__day' : 'input__night'"
                    :placeholder="columns.email.title"
                    v-model="form.email"
                    type="email"
                    size="medium"
                  ></el-input>
                </div>

                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.gender") }}
                  </span>
                  <el-form-item prop="gender_id">
                    <select-gender
                      :size="'medium'"
                      :placeholder="columns.gender_id.title"
                      :id="form.gender_id"
                      v-model="form.gender_id"
                    >
                    </select-gender>
                  </el-form-item>
                </div>

                <div class="app-form__group mb-4 my-upload-2">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                    $t("message.photo")
                  }}</span>
                  <el-upload
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleImageRemove"
                    class="upload-demo"
                    action="/"
                    :limit="1"
                    :on-change="createImageList"
                    :file-list="imageList"
                    list-type="picture-card"
                    :auto-upload="false"
                    :class="mode ? '' : 'upload__mynight'"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </div>
              </el-col>
              <!-- end col -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectGender from "@/components/filters/inventory/select-gender";
import selectSocial from "@/components/filters/inventory/select-social";
import selectStatus from "@/components/filters/inventory/select-status";
import selectVacancy from "@/components/filters/inventory/select-vacancy";
import selectNation from "@/components/filters/inventory/select-nation";
export default {
  mixins: [form, drawer],
  name: "CandidateController",
  components: {
    selectGender,
    selectStatus,
    selectVacancy,
    selectNation,
    selectSocial,
  },
  props: {
    vacancy: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      fileList: [],
      imageList: [],
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  watch: {
    vacancy: {
      handler: function () {
        //   setTimeout(()=>{
        //     this.form.vacancy_ids.push(this.vacancy.id);
        //   })
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      rules: "candidate/rules",
      model: "candidate/model",
      columns: "candidate/columns",
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
      save: "candidate/store",
      empty: "candidate/empty",
    }),
    handleFilePreview(file) {
      console.log(file);
    },
    handleFileRemove(file, fileList) {
      console.log(file, fileList);
    },
    beforeFileRemove(file, fileList) {
      //return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },
    handleImageRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    createFileList(file) {
      this.form.resume = file.raw;
    },
    createImageList(file) {
      this.form.image = file.raw;
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          for (var propName in this.form) {
            if (
              this.form[propName] === null ||
              this.form[propName] === undefined ||
              this.form[propName] == ""
            ) {
              delete this.form[propName];
            }
          }
          for (const key in this.form) {
            formData.append(key, this.form[key]);
            if (key == "vacancy_ids") {
              for (const id in this.form[key]) {
                formData.append("vacancy_ids[]", this.form[key][id]);
              }
            }
          }
          this.save(formData)
            .then((res) => {
              this.parent().listChanged();
              this.$alert(res);
              if (res.status == 201) {
                this.close();
                this.empty();
                this.fileList = [];
                this.imageList = [];
              }
            })
            .catch((err) => {
              this.$alert(err);
            });
        }
      });
    },
    afterOpen() {
      if (this.vacancy) {
        this.form.vacancy_ids.push(this.vacancy.id);
      }
    },
    afterLeave() {
      this.empty();
      this.fileList = [];
      this.imageList = [];
    },
  },
};
</script>
<style  lang="scss">
.my-upload-2 {
  .upload-demo {
    position: relative;
  }
  .el-upload-list {
    position: relative;
    z-index: 2;
  }
  .el-upload.el-upload--picture-card {
    position: absolute;
    left: 0px !important;
    z-index: 1;
  }
}
.upload__mynight{
  .el-upload.el-upload--picture-card{
    background-color: transparent;
  }
}
</style>
